<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" />
    <div class="alert alert-info" role="alert">
      Patcher Versions are global for all environments
    </div>

    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-settings" /> Standard Patcher Versions
          <div class="card-header-actions">
            <small class="text-muted">Total: {{ envStdVersions.length }}</small>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="envStdVersions"
          :fields="versionFields"
          :hover="hover"
          column-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          pagination >

          <template #version="{ item }">
              <td class="py-2">
                  <CInput placeholder="Version" v-model="item.version" />
              </td>
          </template>

          <template #update="{ item }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="updateVersions(item)" >
                Update
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-settings" /> GM Patcher Versions
          <div class="card-header-actions">
            <small class="text-muted">Total: {{ envGmVersions.length }}</small>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="envGmVersions"
          :fields="versionFields"
          :hover="hover"
          column-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          pagination >

          <template #version="{ item }">
              <td class="py-2">
                  <CInput placeholder="Version" v-model="item.version" />
              </td>
          </template>

          <template #update="{ item }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="updateVersions(item)" >
                Update
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "PatchVersions",
  components: {
    Loading,
  },
  data() {
    return {
      envGmVersions: [],
      envStdVersions: [],
      versionFields: [
        { key: "id", label: "ID", filter: false, sorter: false },
        { key: "app", label: "Application", filter: false, sorter: false },
        { key: "os", label: "OS" , filter: false, sorter: false},
        { key: "clientType", label: "Client", filter: false, sorter: false },
        { key: "version", label: "Version", filter: false, sorter: false },
        { key: "update", label: "", filter: false, sorter: false },
      ],
      hover: true,
      isLoading: false,
    };
  },
  methods: {
    getGlobalVersions() {
      this.isLoading = true;
      this.$http
        .get(this.$config.gmapi + "/GameVersion/all", {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.envGmVersions = [];
          this.envStdVersions = [];

          for (let i = 0; i < jsonData.length; i++) {
            if (jsonData[i].clientType == "gm") {
              this.envGmVersions.push(jsonData[i]);
            } else if (jsonData[i].clientType == "standard") {
              this.envStdVersions.push(jsonData[i]);
            }
          }
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.isLoading = false;
        });
    },
    updateVersions(item) {
        this.isLoading = true;
        this.$http.put(this.$config.gmapi + '/GameVersion', item, {headers: this.$config.getHeaders() }).then(response => {
            return response.json();
        }).then(jsonData => {
            this.getGlobalVersions();
        }).catch(function (error) {
          this.$root.$emit('onErr', error);
            this.isLoading = false;
        }); 
    }
  },
  created() {
    this.getGlobalVersions();
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
